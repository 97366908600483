<template>
  <div class="df-width case">
    <div class="case-title">经典案例</div>
    <div class="case-desc">我们能帮你解决问题</div>
    <div class="case-item">
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic1" lazy fit="cover"></el-image>
        <div class="case-item-txt">珠海市青少年妇女儿童活动小程序</div>
        <div class="case-item-desc">
          活动中心是珠海市团市委、市妇联属下的一个集培训、活动、娱乐等功能为一体的校外教育事业单位。活动
        </div>
      </div>
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic2" lazy fit="cover"></el-image>
        <div class="case-item-txt">珠海市青年联合会小程序</div>
        <div class="case-item-desc">
          珠海市青年联合会小程序是一款集新闻发布、活动报名、信息记录的专属珠海青联委员使用的应用小程序
        </div>
      </div>
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic3" lazy fit="cover"></el-image>
        <div class="case-item-txt">珠海华发物业管理服务有限公司官网</div>
        <div class="case-item-desc">
          基于珠海物业管理行业的第一品牌和领跑者的定位和形象，珠海华发物业管理有限公司的网站设计充分体现
        </div>
      </div>
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic4" lazy fit="cover"></el-image>
        <div class="case-item-txt">嘉宝华集团【大粤地产】联合办公小程序</div>
        <div class="case-item-desc">
          联合办公小程序是一款为联合办公打造的高端服务平台，为客户解决了实际线下的业务流程，并解决了高端
        </div>
      </div>
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic5" lazy fit="cover"></el-image>
        <div class="case-item-txt">珠海青年人才驿站服务平台</div>
        <div class="case-item-desc">
          珠海青年人才驿站是为来珠海求职的青年人才提供免费住宿、就业指导、城市融入等相关服务，精准服务青
        </div>
      </div>
      <div class="case-item-box" @click="goToUrl('/case/1.html')">
        <el-image class="case-item-pic" :src="casePic6" lazy fit="cover"></el-image>
        <div class="case-item-txt">珠海青年人才驿站服务平台</div>
        <div class="case-item-desc">
          珠海市人民医院新官网，将互联网技术与珠海市人民医院服务理念、诊疗特点、科研成果进行有机结合，构造
        </div>
      </div>
    </div>
    <div class="about-tab-page">
      <el-pagination :page-size="9" background layout="pager" :total="6" prev-text="上一页" next-text="下一页"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Case',
  data () {
    return {
      casePic1: require('@/assets/images/pro1.png'),
      casePic2: require('@/assets/images/pro2.png'),
      casePic3: require('@/assets/images/pro3.png'),
      casePic4: require('@/assets/images/pro4.png'),
      casePic5: require('@/assets/images/pro5.png'),
      casePic6: require('@/assets/images/pro6.png')
    }
  },
  methods: {
    goToUrl (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
  .about-tab-page {
    text-align: center;
    margin-top: 40px;
  }
  :deep(.el-pagination.is-background .el-pager li:not(.disabled)) {
    background-color: #fff;
    color: #ccc;
    border: 1px solid #ccc;
    font-size: 18px;
    font-weight: normal;
  }
  :deep(.el-pagination.is-background .el-pager li:not(.disabled).active, .el-pagination.is-background .el-pager li:not(.disabled):hover) {
    background-color: #fff;
    color: var(--default-color);
    border: 1px solid var(--default-color);
  }
  .case {
    padding: 60px 0;
  }
  .case-title {
    text-align: center;
    color: var(--color-text);
    font-size: 48px;
  }
  .case-desc {
    color: var(--color-text);
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }
  .case-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .case-item-box {
    width: 382px;
    height: 324px;
    padding: 15px;
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    margin-right: 18px;
    cursor: pointer;
  }
  .case-item-box:hover {
    border: none;
    box-shadow: 0 6px 12px 0 rgba(179, 179, 179, 0.5);
  }
  .case-item-box .el-image {
    width: 100%;
    height: 185px;
  }
  .case-item-box:nth-child(3) {
    margin-right: 0;
  }
  .case-item-txt {
    color: var(--color-text);
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .case-item-desc {
    color: #b3b3b3;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  :deep(.el-image__inner) {
    width: 100%;
    /*height: auto;*/
  }
</style>
